// ** Initial State
const initialState = {
    allData: [],
    data: [],
    totalPages: 1,
    params: {},
    selectedTrucker: null,
    truckerId: null,
    cnh: null,
    rgFront: null,
    rgBack: null,
    cepValue: null,
}

const trucker = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ALL_TRUCKERS_DATA':
            return { ...state, allData: action.data }
        case 'GET_TRUCKERS_DATA':
            return {
                ...state,
                data: action.data,
                totalPages: action.totalPages,
                params: action.params,
            }
        case 'GET_TRUCKER':
            return { ...state, selectedTrucker: action.selectedTrucker }
        case 'GET_TRUCKER_CNH':
            return { ...state, cnh: action.cnh }
        case 'GET_TRUCKER_RG_FRONT':
            return { ...state, rgFront: action.rgFront }
        case 'GET_TRUCKER_RG_BACK':
            return { ...state, rgBack: action.rgBack }
        case 'ADD_TRUCKER':
            return { ...state, truckerId: action.truckerId }
        case 'DELETE_TRUCKER':
            return { ...state }
        case 'DELETE_TRUCKER_CNH':
            return { ...state, cnh: action.cnh }
        case 'DELETE_TRUCKER_RG_FRONT':
            return { ...state, rgFront: action.rgFront }
        case 'DELETE_TRUCKER_RG_BACK':
            return { ...state, rgBack: action.rgBack }
        case 'UPDATE_TRUCKER':
            return { ...state }
        case 'CLEAR_TRUCKER':
            return { ...state, selectedTrucker: action.selectedTrucker }
        case 'CLEAR_TRUCKER_CNH':
            return { ...state, cnh: null }
        case 'UPDATE_TRUCKER_DOCUMENT':
            return { ...state, selectedTrucker: action.selectedTrucker }
        case 'UPDATE_TRUCKER_DOCUMENTS':
            return { ...state }
        case 'ADD_TRUCKER_DOCUMENTS':
            return { ...state }
        case 'UPDATE_TRUCKER_LOCATION':
            return { ...state }
        case 'ADD_TRUCKER_LOCATION':
            return { ...state }
        case 'UPDATE_TRUCKER_CONTACT':
            return { ...state }
        case 'DELETE_TRUCKER_CONTACT':
            return { ...state }
        case 'ADD_TRUCKER_CONTACT':
            return { ...state }
        case 'UPDATE_TRUCKER_BANK':
            return { ...state }
        case 'RESET':
            return { ...(state = initialState) }
        case 'GET_CEP':
            return {
                ...state,
                cepValue: action.cepValue,
            }
        default:
            return { ...state }
    }
}

export default trucker
