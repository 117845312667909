const initialState = { data: null, shipping: null, payment: null, params: {} }

const dashboardData = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_INCIDENTS_DATA':
            return { ...state, data: action.data, params: action.params }
        case 'GET_SHIPPING_DATA':
            return {
                ...state,
                shipping: action.shipping,
            }
        case 'GET_PAYMENT_DATA':
            return {
                ...state,
                payment: action.payment,
            }
        default:
            return { ...state }
    }
}

export default dashboardData
