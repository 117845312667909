// ** JWT config
import jwtDefaultConfig from '@src/@core/auth/jwt/jwtDefaultConfig'

// ** Socket.io
import { io } from 'socket.io-client'

// ** App token
const accessToken = localStorage.getItem(jwtDefaultConfig.storageTokenKeyName)

const initialState = {
    host: null,
    socket: null,
    notification: null,
}

const socketReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'WS_CONNECT':
            if (state.socket !== null) {
                state.socket = null
            } else {
                state.socket = io(action.host, {
                    auth: { token: accessToken?.replaceAll('"', '') },
                })
                state.socket.on('connect_error', (error) => {
                    console.log(error)
                })
            }
            return { ...state, host: action.host }
        case 'WS_DISCONNECT':
            if (state.socket !== null) {
                state.socket = null
            }
            console.log('websocket closed')
            return { ...state, host: null }
        case 'GET_NOTIFICATION':
            return {
                ...state,
                notification: action.notification,
            }
        case 'UPDATE_NOTIFICATION':
            return { ...state }
        default:
            return state
    }
}

export default socketReducer
