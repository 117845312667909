const initialState = {
    data: [],
}

const mobileLoading = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_LOADING_DATA':
            return { ...state, data: action.data }
        case 'ADD_LOADING_INCIDENT':
            return { ...state }
        default:
            return { ...state }
    }
}

export default mobileLoading
