import mock from '../mock'

const autoComplete = [
    {
        title: 'The Shawshank Redemption',
        rank: '1',
        id: 'tt0111161',
    },
    {
        title: 'The Godfather',
        rank: '2',
        id: 'tt0068646',
    },
    {
        title: 'The Godfather: Part II',
        rank: '3',
        id: 'tt0071562',
    },
    {
        title: 'Pulp Fiction',
        rank: '4',
        id: 'tt0110912',
    },
    {
        title: 'The Good, the Bad and the Ugly',
        rank: '5',
        id: 'tt0060196',
    },
    {
        title: 'The Dark Knight',
        rank: '6',
        id: 'tt0468569',
    },
    {
        title: '12 Angry Men',
        rank: '7',
        id: 'tt0050083',
    },
    {
        title: "Schindler's List",
        rank: '8',
        id: 'tt0108052',
    },
    {
        title: 'The Lord of the Rings: The Return of the King',
        rank: '9',
        id: 'tt0167260',
    },
    {
        title: 'Fight Club',
        rank: '10',
        id: 'tt0137523',
    },
    {
        title: 'Star Wars: Episode V - The Empire Strikes Back',
        rank: '11',
        id: 'tt0080684',
    },
    {
        title: 'The Lord of the Rings: The Fellowship of the Ring',
        rank: '12',
        id: 'tt0120737',
    },
    {
        title: "One Flew Over the Cuckoo's Nest",
        rank: '13',
        id: 'tt0073486',
    },
    {
        title: 'Inception',
        rank: '14',
        id: 'tt1375666',
    },
    {
        title: 'Goodfellas',
        rank: '15',
        id: 'tt0099685',
    },
    {
        title: 'Star Wars',
        rank: '16',
        id: 'tt0076759',
    },
    {
        title: 'Seven Samurai',
        rank: '17',
        id: 'tt0047478',
    },
    {
        title: 'Forrest Gump',
        rank: '18',
        id: 'tt0109830',
    },
    {
        title: 'The Matrix',
        rank: '19',
        id: 'tt0133093',
    },
    {
        title: 'The Lord of the Rings: The Two Towers',
        rank: '20',
        id: 'tt0167261',
    },
    {
        title: 'City of God',
        rank: '21',
        id: 'tt0317248',
    },
    {
        title: 'Se7en',
        rank: '22',
        id: 'tt0114369',
    },
    {
        title: 'The Silence of the Lambs',
        rank: '23',
        id: 'tt0102926',
    },
    {
        title: 'Once Upon a Time in the West',
        rank: '24',
        id: 'tt0064116',
    },
    {
        title: 'Casablanca',
        rank: '25',
        id: 'tt0034583',
    },
    {
        title: 'The Usual Suspects',
        rank: '26',
        id: 'tt0114814',
    },
    {
        title: 'Raiders of the Lost Ark',
        rank: '27',
        id: 'tt0082971',
    },
    {
        title: 'Rear Window',
        rank: '28',
        id: 'tt0047396',
    },
    {
        title: "It's a Wonderful Life",
        rank: '29',
        id: 'tt0038650',
    },
    {
        title: 'Psycho',
        rank: '30',
        id: 'tt0054215',
    },
    {
        title: 'Léon: The Professional',
        rank: '31',
        id: 'tt0110413',
    },
    {
        title: 'Sunset Blvd.',
        rank: '32',
        id: 'tt0043014',
    },
    {
        title: 'American History X',
        rank: '33',
        id: 'tt0120586',
    },
    {
        title: 'Apocalypse Now',
        rank: '34',
        id: 'tt0078788',
    },
    {
        title: 'Terminator 2: Judgment Day',
        rank: '35',
        id: 'tt0103064',
    },
    {
        title: 'Saving Private Ryan',
        rank: '36',
        id: 'tt0120815',
    },
    {
        title: 'Memento',
        rank: '37',
        id: 'tt0209144',
    },
    {
        title: 'City Lights',
        rank: '38',
        id: 'tt0021749',
    },
    {
        title: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb',
        rank: '39',
        id: 'tt0057012',
    },
    {
        title: 'Alien',
        rank: '40',
        id: 'tt0078748',
    },
    {
        title: 'Modern Times',
        rank: '41',
        id: 'tt0027977',
    },
    {
        title: 'Spirited Away',
        rank: '42',
        id: 'tt0245429',
    },
    {
        title: 'North by Northwest',
        rank: '43',
        id: 'tt0053125',
    },
    {
        title: 'Back to the Future',
        rank: '44',
        id: 'tt0088763',
    },
    {
        title: 'Life Is Beautiful',
        rank: '45',
        id: 'tt0118799',
    },
    {
        title: 'The Shining',
        rank: '46',
        id: 'tt0081505',
    },
    {
        title: 'The Pianist',
        rank: '47',
        id: 'tt0253474',
    },
    {
        title: 'Citizen Kane',
        rank: '48',
        id: 'tt0033467',
    },
    {
        title: 'The Departed',
        rank: '49',
        id: 'tt0407887',
    },
    {
        title: 'M',
        rank: '50',
        id: 'tt0022100',
    },
    {
        title: 'Paths of Glory',
        rank: '51',
        id: 'tt0050825',
    },
    {
        title: 'Vertigo',
        rank: '52',
        id: 'tt0052357',
    },
    {
        title: 'Django Unchained',
        rank: '53',
        id: 'tt1853728',
    },
    {
        title: 'Double Indemnity',
        rank: '54',
        id: 'tt0036775',
    },
    {
        title: 'The Dark Knight Rises',
        rank: '55',
        id: 'tt1345836',
    },
    {
        title: 'Aliens',
        rank: '56',
        id: 'tt0090605',
    },
    {
        title: 'Taxi Driver',
        rank: '57',
        id: 'tt0075314',
    },
    {
        title: 'American Beauty',
        rank: '58',
        id: 'tt0169547',
    },
    {
        title: 'The Green Mile',
        rank: '59',
        id: 'tt0120689',
    },
    {
        title: 'Gladiator',
        rank: '60',
        id: 'tt0172495',
    },
    {
        title: 'The Intouchables',
        rank: '61',
        id: 'tt1675434',
    },
    {
        title: 'WALL·E',
        rank: '62',
        id: 'tt0910970',
    },
    {
        title: 'The Lives of Others',
        rank: '63',
        id: 'tt0405094',
    },
    {
        title: 'Toy Story 3',
        rank: '64',
        id: 'tt0435761',
    },
    {
        title: 'The Great Dictator',
        rank: '65',
        id: 'tt0032553',
    },
    {
        title: 'The Prestige',
        rank: '66',
        id: 'tt0482571',
    },
    {
        title: 'A Clockwork Orange',
        rank: '67',
        id: 'tt0066921',
    },
    {
        title: 'Lawrence of Arabia',
        rank: '68',
        id: 'tt0056172',
    },
    {
        title: 'Amélie',
        rank: '69',
        id: 'tt0211915',
    },
    {
        title: 'To Kill a Mockingbird',
        rank: '70',
        id: 'tt0056592',
    },
    {
        title: 'Reservoir Dogs',
        rank: '71',
        id: 'tt0105236',
    },
    {
        title: 'Das Boot',
        rank: '72',
        id: 'tt0082096',
    },
    {
        title: 'The Lion King',
        rank: '73',
        id: 'tt0110357',
    },
    {
        title: 'Cinema Paradiso',
        rank: '74',
        id: 'tt0095765',
    },
    {
        title: 'Star Wars: Episode VI - Return of the Jedi',
        rank: '75',
        id: 'tt0086190',
    },
    {
        title: 'The Treasure of the Sierra Madre',
        rank: '76',
        id: 'tt0040897',
    },
    {
        title: 'The Third Man',
        rank: '77',
        id: 'tt0041959',
    },
    {
        title: 'Once Upon a Time in America',
        rank: '78',
        id: 'tt0087843',
    },
    {
        title: 'Requiem for a Dream',
        rank: '79',
        id: 'tt0180093',
    },
    {
        title: 'Eternal Sunshine of the Spotless Mind',
        rank: '80',
        id: 'tt0338013',
    },
    {
        title: 'Full Metal Jacket',
        rank: '81',
        id: 'tt0093058',
    },
    {
        title: 'Oldboy',
        rank: '82',
        id: 'tt0364569',
    },
    {
        title: 'Braveheart',
        rank: '83',
        id: 'tt0112573',
    },
    {
        title: 'L.A. Confidential',
        rank: '84',
        id: 'tt0119488',
    },
    {
        title: 'Bicycle Thieves',
        rank: '85',
        id: 'tt0040522',
    },
    {
        title: 'Chinatown',
        rank: '86',
        id: 'tt0071315',
    },
    {
        title: "Singin' in the Rain",
        rank: '87',
        id: 'tt0045152',
    },
    {
        title: 'Princess Mononoke',
        rank: '88',
        id: 'tt0119698',
    },
    {
        title: 'Monty Python and the Holy Grail',
        rank: '89',
        id: 'tt0071853',
    },
    {
        title: 'Metropolis',
        rank: '90',
        id: 'tt0017136',
    },
    {
        title: 'Rashomon',
        rank: '91',
        id: 'tt0042876',
    },
    {
        title: 'Some Like It Hot',
        rank: '92',
        id: 'tt0053291',
    },
    {
        title: 'Amadeus',
        rank: '93',
        id: 'tt0086879',
    },
    {
        title: '2001: A Space Odyssey',
        rank: '94',
        id: 'tt0062622',
    },
    {
        title: 'All About Eve',
        rank: '95',
        id: 'tt0042192',
    },
    {
        title: 'Witness for the Prosecution',
        rank: '96',
        id: 'tt0051201',
    },
    {
        title: 'The Sting',
        rank: '97',
        id: 'tt0070735',
    },
    {
        title: 'The Apartment',
        rank: '98',
        id: 'tt0053604',
    },
    {
        title: 'Grave of the Fireflies',
        rank: '99',
        id: 'tt0095327',
    },
    {
        title: 'Indiana Jones and the Last Crusade',
        rank: '100',
        id: 'tt0097576',
    },
]

mock.onGet('/api/autocomplete/data').reply(200, {
    autoComplete,
})
