// ** Initial State
const initialState = {
    allData: [],
    data: [],
    totalPages: 1,
    params: {},
    selectedSector: null,
}

const sector = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ALL_SECTORS_DATA':
            return { ...state, allData: action.data }

        case 'GET_SECTOR_DATA':
            return {
                ...state,
                data: action.data,
                totalPages: action.totalPages,
                params: action.params,
            }
        case 'GET_SECTOR':
            return { ...state, selectedSector: action.selectedSector }
        case 'ADD_SECTOR':
            return { ...state }
        case 'DELETE_SECTOR':
            return { ...state }
        case 'UPDATE_SECTOR':
            return { ...state }
        case 'CLEAR_SECTOR':
            return { ...state, selectedSector: action.selectedSector }
        default:
            return { ...state }
    }
}

export default sector
