// ** Initial State
const initialState = {
    allData: [],
    data: [],
    totalPages: 1,
    params: {},
    selectedSchedule: null,
    incidentId: null,
    incidentFile: null,
    deliveryFile: null,
}

const schedule = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ALL_SCHEDULES_DATA':
            return { ...state, allData: action.data }
        case 'GET_SCHEDULE_DATA':
            return {
                ...state,
                data: action.data,
                totalPages: action.totalPages,
                params: action.params,
            }
        case 'GET_SCHEDULE':
            return { ...state, selectedSchedule: action.selectedSchedule }
        case 'GET_INCIDENT_FILE':
            return { ...state, incidentFile: action.incidentFile }
        case 'GET_DELIVERY_FILE':
            return { ...state, deliveryFile: action.deliveryFile }
        case 'CLEAR_SCHEDULE':
            return { ...state, selectedSchedule: action.selectedSchedule }
        case 'CLEAR_IMAGE':
            return { ...state, incidentFile: action.incidentFile }
        case 'CLEAR_INCIDENT':
            return { ...state, incidentId: action.incidentId }
        case 'ADD_SCHEDULE':
            return { ...state }
        case 'ADD_INCIDENT':
            return { ...state, incidentId: action.incidentId }
        case 'DELETE_SCHEDULE':
            return { ...state }
        case 'UPDATE_SCHEDULE':
            return { ...state }
        case 'UPLOAD_SCHEDULE_DOCUMENT':
            return { ...state }
        default:
            return { ...state }
    }
}

export default schedule
