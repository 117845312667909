// ** Initial State
const initialState = {
    allData: [],
    data: [],
    totalPages: 1,
    params: {},
    selectedQuotation: null,
    costTable: null,
    base64: null,
    file: null,
    numberCheck: null,
    fletTrucker: null,
}

const quotation = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ALL_QUOTATIONS_DATA':
            return { ...state, allData: action.data }
        case 'GET_QUOTATIONS_DATA':
            return {
                ...state,
                data: action.data,
                totalPages: action.totalPages,
                params: action.params,
            }
        case 'GET_QUOTATION':
            return { ...state, selectedQuotation: action.selectedQuotation }
        case 'GET_COST_TABLE':
            return { ...state, costTable: action.costTable }
        case 'GET_FILE':
            return { ...state, file: action.file }
        case 'GET_CONTRACT':
            return { ...state, base64: action.base64 }
        case 'ADD_QUOTATION':
            return { ...state }
        case 'DELETE_QUOTATION':
            return { ...state }
        case 'UPDATE_QUOTATION':
            return { ...state }
        case 'UPDATE_TRUCKERS':
            return { ...state }
        case 'CLEAR_QUOTATION':
            return { ...state, selectedQuotation: action.selectedQuotation }
        case 'UPDATE_QUOTATION_LOCATION':
            return { ...state }
        case 'ADD_QUOTATION_LOCATION':
            return { ...state }
        case 'UPDATE_QUOTATION_CONTACT':
            return { ...state }
        case 'DELETE_QUOTATION_CONTACT':
            return { ...state }
        case 'DELETE_CONTRACT':
            return { ...state }
        case 'DELETE_FILE':
            return { ...state, file: action.file }
        case 'ADD_QUOTATION_CONTACT':
            return { ...state }
        case 'ADD_QUOTATION_BANK':
            return { ...state }
        case 'UPDATE_QUOTATION_BANK':
            return { ...state }
        case 'UPDATE_RISK_DOCUMENT':
            return { ...state }
        case 'UPDATE_RISK_MANAGER':
            return { ...state, base64: action.base64 }
        case 'NUMBER_CHECK':
            return { ...state, numberCheck: action.numberCheck }
        case 'SET_FLET_TRUCKER':
            return { ...state, fletTrucker: action.fletTrucker }
        case 'CLEAR':
            return { ...state, data: action.data }
        default:
            return { ...state }
    }
}

export default quotation
