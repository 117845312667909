const initialState = {
    data: [],
}

const mobileDelivery = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_DELIVERY_DATA':
            return { ...state, data: action.data }
        case 'UPDATE_CONTRACT':
            return { ...state }
        default:
            return { ...state }
    }
}

export default mobileDelivery
