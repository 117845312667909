// ** Initial State
const initialState = {
    allData: [],
    data: [],
    totalPages: 1,
    params: {},
    selectedConfig: null,
    selectedType: null,
    incidentTypes: null,
    incidentId: null,
    editIncident: false,
    refusalTypes: null,
    editRefusalType: false,
    refusalTypeId: null,
    selectedRefusalType: null,
    riskManagers: null,
    editRiskManager: false,
    riskMananerId: null,
    selectedRiskManager: null,
    truckSegments: null,
    selectedTruckSegment: null,
    editTruckSegment: null,
}

const config = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ALL_CONFIG_DATA':
            return { ...state, allData: action.data }

        case 'GET_CONFIG_DATA':
            return {
                ...state,
                data: action.data,
                totalPages: action.totalPages,
                params: action.params,
            }
        case 'GET_CONFIG':
            return { ...state, selectedConfig: action.selectedConfig }
        case 'GET_INCIDENT_TYPES':
            return { ...state, incidentTypes: action.incidentTypes }
        case 'GET_REFUSAL_TYPES':
            return { ...state, refusalTypes: action.refusalTypes }
        case 'GET_RISK_ALL_MANAGERS':
            return { ...state, riskManagers: action.riskManagers }
        case 'GET_TRUCK_SEGMENTS':
            return { ...state, truckSegments: action.truckSegments }
        case 'GET_INCIDENT_TYPE':
            return { ...state, selectedType: action.selectedType }
        case 'GET_REFUSAL_TYPE':
            return { ...state, selectedRefusalType: action.selectedRefusalType }
        case 'GET_RISK_MANAGER':
            return { ...state, selectedRiskManager: action.selectedRiskManager }
        case 'GET_TRUCK_SEGMENT':
            return {
                ...state,
                selectedTruckSegment: action.selectedTruckSegment,
            }
        case 'GET_REFUSAL_TYPE_ID':
            return { ...state, refusalTypeId: action.refusalTypeId }
        case 'GET_INCIDENT_ID':
            return { ...state, incidentId: action.incidentId }
        case 'GET_RISK_MANAGER_ID':
            return { ...state, riskMananerId: action.riskMananerId }
        case 'ADD_CONFIG':
            return { ...state }
        case 'ADD_INCIDENT':
            return { ...state }
        case 'ADD_REFUSAL_TYPE':
            return { ...state }
        case 'ADD_TRUCK_SEGMENT':
            return { ...state }
        case 'DELETE_CONFIG':
            return { ...state }
        case 'DELETE_INCIDENT':
            return { ...state }
        case 'DELETE_REFUSAL_TYPE':
            return { ...state }
        case 'DELETE_TRUCK_SEGMENT':
            return { ...state }
        case 'UPDATE_CONFIG':
            return { ...state }
        case 'UPDATE_INCIDENT_TYPE':
            return { ...state }
        case 'UPDATE_REFUSAL_TYPE':
            return { ...state }
        case 'UPDATE_TRUCK_SEGMENT':
            return { ...state }
        case 'EDIT_INCIDENT':
            return { ...state, editIncident: action.editIncident }
        case 'EDIT_REFUSAL_TYPE':
            return { ...state, editRefusalType: action.editRefusalType }
        case 'EDIT_RISK_MANAGER':
            return { ...state, editRiskManager: action.editRiskManager }
        case 'EDIT_TRUCK_SEGMENT':
            return { ...state, editTruckSegment: action.editTruckSegment }
        case 'CLEAR_CONFIG':
            return { ...state, selectedConfig: action.selectedConfig }
        case 'CLEAR_INCIDENT_TYPE':
            return { ...state, selectedType: action.selectedType }
        case 'CLEAR_REFUSAL_TYPE':
            return { ...state, selectedRefusalType: action.selectedRefusalType }
        case 'CLEAR_RISK_MANAGER':
            return { ...state, selectedRiskManager: action.selectedRiskManager }
        case 'CLEAR_TRUCK_SEGMENT':
            return {
                ...state,
                selectedTruckSegment: action.selectedTruckSegment,
            }
        default:
            return { ...state }
    }
}

export default config
