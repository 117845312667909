// ** Initial State
const initialState = {
    allData: [],
    data: [],
    totalPages: 1,
    params: {},
    selectedBrand: null,
    selectedModel: null,
    allModels: null,
}

const truckModels = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ALL_BRANDS_DATA':
            return { ...state, allData: action.data }

        case 'GET_BRAND_DATA':
            return {
                ...state,
                data: action.data,
                totalPages: action.totalPages,
                params: action.params,
            }
        case 'GET_BRAND':
            return { ...state, selectedBrand: action.selectedBrand }
        case 'ADD_BRAND':
            return { ...state }
        case 'ADD_MODEL':
            return { ...state }
        case 'DELETE_BRAND':
            return { ...state }
        case 'DELETE_MODEL':
            return { ...state }
        case 'UPDATE_BRAND':
            return { ...state }
        case 'UPDATE_MODEL':
            return { ...state }
        case 'CLEAR_BRAND':
            return { ...state, selectedBrand: action.selectedBrand }
        case 'CLEAR_MODEL':
            return { ...state, selectedModel: action.selectedModel }
        case 'GET_MODEL':
            return { ...state, selectedModel: action.selectedModel }
        case 'GET_MODELS':
            return { ...state, allModels: action.allModels }
        default:
            return { ...state }
    }
}

export default truckModels
