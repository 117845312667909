// ** Initial State
const initialState = {
    allData: [],
    data: [],
    totalPages: 1,
    params: {},
    selectedCategory: null,
}

const category = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ALL_CATEGORIES_DATA':
            return { ...state, allData: action.data }

        case 'GET_CATEGORY_DATA':
            return {
                ...state,
                data: action.data,
                totalPages: action.totalPages,
                params: action.params,
            }
        case 'GET_CATEGORY':
            return { ...state, selectedCategory: action.selectedCategory }
        case 'ADD_CATEGORY':
            return { ...state }
        case 'DELETE_CATEGORY':
            return { ...state }
        case 'UPDATE_CATEGORY':
            return { ...state }
        case 'CLEAR_CATEGORY':
            return { ...state, selectedCategory: action.selectedCategory }
        case 'RESET':
            return { ...(state = initialState) }
        default:
            return { ...state }
    }
}

export default category
