// **  Initial State
const initialState = {
    riskManagers: [],
    scheduleIncidentTypes: [],
}

const coreReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_SCHEDULE_STATUSES':
            return {
                ...state,
                status: {
                    Approval: 'Aprovação',
                    Negotiation: 'Negociação',
                    Loading: 'Carregamento',
                    Transporting: 'Transportando',
                    Occurrence: 'Ocorrência',
                    Delivered: 'Entregue',
                    Invoiced: 'Faturado',
                    Canceled: 'Cancelado',
                },
            }
        case 'GET_UF_LIST':
            return {
                ...state,
                states: [
                    { label: 'Acre', value: 'AC' },
                    { label: 'Alagoas', value: 'AL' },
                    { label: 'Amapá', value: 'AP' },
                    { label: 'Amazonas', value: 'AM' },
                    { label: 'Bahia', value: 'BA' },
                    { label: 'Ceará', value: 'CE' },
                    { label: 'Espírito Santo', value: 'ES' },
                    { label: 'Goiás', value: 'GO' },
                    { label: 'Maranhão', value: 'MA' },
                    { label: 'Mato Grosso', value: 'MT' },
                    { label: 'Mato Grosso do Sul', value: 'MS' },
                    { label: 'Minas Gerais', value: 'MG' },
                    { label: 'Pará', value: 'PA' },
                    { label: 'Paraíba', value: 'PB' },
                    { label: 'Paraná', value: 'PR' },
                    { label: 'Pernambuco', value: 'PE' },
                    { label: 'Piauí', value: 'PI' },
                    { label: 'Rio de Janeiro', value: 'RJ' },
                    { label: 'Rio Grande do Norte', value: 'RN' },
                    { label: 'Rio Grande do Sul', value: 'RS' },
                    { label: 'Rondônia', value: 'RO' },
                    { label: 'Roraima', value: 'RR' },
                    { label: 'Santa Catarina', value: 'SC' },
                    { label: 'São Paulo', value: 'SP' },
                    { label: 'Sergipe', value: 'SE' },
                    { label: 'Tocantins', value: 'TO' },
                    { label: 'Distrito Federal', value: 'DF' },
                ],
            }
        case 'GET_LOAD_ACCOMODATION':
            return {
                ...state,
                accommodation: [
                    { label: 'Selecionar tipo de carga', value: '' },
                    { label: 'Carga seca', value: 'Dry' },
                    { label: 'Baú', value: 'Trunk' },
                    { label: 'Sider', value: 'Sider' },
                    { label: 'Graneleiro', value: 'Bulk Carrier' },
                ],
            }
        case 'GET_RISK_MANAGERS':
            return {
                ...state,
                riskManagers: [...action.data],
            }
        case 'GET_SCHEDULE_INCIDENT_TYPES':
            return {
                ...state,
                scheduleIncidentTypes: [...action.data],
            }
        default:
            return state
    }
}

export default coreReducer
