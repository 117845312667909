// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import core from './core'
import navbar from './navbar'
import layout from './layout'
import websocket from './websocket'

import user from '@src/views/user/store/reducer'
import company from '@src/views/company/store/reducer'
import trucker from '@src/views/trucker/store/reducer'
import payment from '@src/views/payment/store/reducer'
import truck from '@src/views/truck/store/reducer'
import product from '@src/views/product/store/reducer'
import cost from '@src/views/cost/store/reducer'
import quotation from '@src/views/quotation/store/reducer'
import schedule from '@src/views/schedule/store/reducer'
import permission from '@src/views/permission/store/reducer'
import config from '@src/views/settings/store/reducer'
import category from '@src/views/categories/store/reducer'
import truckModels from '@src/views/truck_models/store/reducer'
import sectors from '@src/views/companies_sectors/store/reducer'
import mobileContract from '@src/views/mobile_contract/store/reducer'
import mobileDelivery from '@src/views/schedule/screens/mobile/delivery_mobile/store/reducer'
import mobileLoading from '@src/views/schedule/screens/mobile/loading_mobile/store/reducer'
import dashboardData from '@src/views/dashboard/store/reducer'

const rootReducer = combineReducers({
    auth,
    core,
    user,
    company,
    dashboardData,
    sectors,
    category,
    trucker,
    truck,
    truckModels,
    mobileContract,
    mobileDelivery,
    mobileLoading,
    navbar,
    layout,
    product,
    payment,
    cost,
    quotation,
    schedule,
    permission,
    config,
    websocket,
})

export default rootReducer
