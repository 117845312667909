// ** Initial State
const initialState = {
    allData: [],
    data: [],
    totalPages: 1,
    params: {},
    selectedPermission: null,
}

const permission = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ALL_PERMISSIONS_DATA':
            return { ...state, allData: action.data }
        case 'GET_PERMISSIONS_DATA':
            return {
                ...state,
                data: action.data,
                totalPages: action.totalPages,
                params: action.params,
            }
        case 'GET_PERMISSION':
            return { ...state, selectedPermission: action.selectedPermission }
        case 'CLEAR_PERMISSION':
            return { ...state, selectedPermission: action.selectedPermission }
        default:
            return { ...state }
    }
}

export default permission
