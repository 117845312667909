// ** Initial State
const initialState = {
    allData: [],
    data: [],
    totalPages: 1,
    params: {},
    selectedPayment: null,
}

const payment = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ALL_PAYMENT_DATA':
            return { ...state, allData: action.data }
        case 'GET_PAYMENT_DATA':
            return {
                ...state,
                data: action.data,
                totalPages: action.totalPages,
                params: action.params,
            }
        case 'GET_PAYMENT':
            return { ...state, selectedPayment: action.selectedPayment }
        default:
            return { ...state }
    }
}

export default payment
