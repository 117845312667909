// ** Initial State
const initialState = {
    allData: [],
    data: [],
    totalPages: 1,
    params: {},
    selectedTruck: null,
    crlv: null,
    rgFront: null,
    rgBack: null,
    brands: [],
    cepValue: null,
    truckId: null,
    ownerId: null,
    suggestions: null,
}

const truck = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ALL_TRUCKS_DATA':
            return { ...state, allData: action.data }
        case 'GET_TRUCKS_DATA':
            return {
                ...state,
                data: action.data,
                totalPages: action.totalPages,
                params: action.params,
            }
        case 'GET_BRANDS':
            return { ...state, brands: action.data }
        case 'GET_TRUCK':
            return { ...state, selectedTruck: action.selectedTruck }
        case 'GET_TRUCK_CRLV':
            return { ...state, crlv: action.crlv }
        case 'GET_TRUCK_RG_FRONT':
            return { ...state, rgFront: action.rgFront }
        case 'GET_TRUCK_RG_BACK':
            return { ...state, rgBack: action.rgBack }
        case 'GET_SUGGESTION':
            return { ...state, suggestions: action.suggestions }
        case 'ADD_TRUCK':
            return { ...state, truckId: action.truckId }
        case 'ADD_OWNER':
            return { ...state, ownerId: action.ownerId }
        case 'UPDATE_TRUCK_DETAIL':
            return { ...state }
        case 'UPDATE_TRUCK_OWNER':
            return { ...state }
        case 'UPDATE_TRUCK_DOCUMENT':
            return { ...state, selectedTruck: action.selectedTruck }
        case 'DELETE_TRUCK':
            return { ...state }
        case 'DELETE_TRUCK_CRLV':
            return { ...state, crlv: action.crlv }
        case 'DELETE_TRUCK_RG_FRONT':
            return { ...state, rgFront: action.rgFront }
        case 'DELETE_TRUCK_RG_BACK':
            return { ...state, rgBack: action.rgBack }
        case 'UPDATE_TRUCK':
            return { ...state }
        case 'UPDATE_OWNER':
            return { ...state }
        case 'CLEAR_TRUCK':
            return { ...state, selectedTruck: action.selectedTruck }
        case 'CLEAR_SUGGESTION':
            return { ...state, suggestions: action.suggestions }
        case 'CLEAR_DOCS':
            return { ...state, crlv: null, rgFront: null, rgBack: null }
        case 'GET_CEP':
            return {
                ...state,
                cepValue: action.cepValue,
            }
        case 'RESET':
            return { ...(state = initialState) }
        default:
            return { ...state }
    }
}

export default truck
