// ** Initial State
const initialState = {
    allData: [],
    data: [],
    totalPages: 1,
    params: {},
    selectedCost: null,
    costCity: null,
    stateProduct: null,
    stateClient: null,
    cityClient: null,
    cityProduct: null,
    productClient: null,
    newCity: false,
    newProduct: false,
    newClient: false,
    newStateProduct: false,
    newStateClient: false,
    newCityClient: false,
}

const cost = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ALL_DATA':
            return { ...state, allData: action.data }

        case 'GET_COST_DATA':
            return {
                ...state,
                data: action.data,
                totalPages: action.totalPages,
                params: action.params,
            }
        case 'GET_COST':
            return { ...state, selectedCost: action.selectedCost }
        case 'GET_COST_CITY':
            return { ...state, costCity: action.costCity }
        case 'GET_STATE_PRODUCT':
            return { ...state, stateProduct: action.stateProduct }
        case 'GET_STATE_CLIENT':
            return { ...state, stateClient: action.stateClient }
        case 'GET_CITY_CLIENT':
            return { ...state, cityClient: action.cityClient }
        case 'GET_CITY_PRODUCT':
            return { ...state, cityProduct: action.cityProduct }
        case 'GET_PRODUCT_CLIENT':
            return { ...state, productClient: action.productClient }
        case 'ADD_COST':
            return { ...state }
        case 'ADD_CITY':
            return { ...state }
        case 'ADD_CLIENT':
            return { ...state }
        case 'ADD_PRODUCT':
            return { ...state }
        case 'DELETE_COST':
            return { ...state }
        case 'NEW_PRODUCT':
            return { ...state, newProduct: action.newProduct }
        case 'NEW_CITY':
            return { ...state, newCity: action.newCity }
        case 'NEW_COST':
            return { ...state, newCost: action.newCost }
        case 'NEW_CLIENT':
            return { ...state, newClient: action.newClient }
        case 'NEW_STATE_PRODUCT':
            return { ...state, newStateProduct: action.newStateProduct }
        case 'NEW_STATE_CLIENT':
            return { ...state, newStateClient: action.newStateClient }
        case 'NEW_CITY_CLIENT':
            return { ...state, newCityClient: action.newCityClient }
        case 'UPDATE_COST':
            return { ...state }
        case 'CLEAR_COST':
            return { ...state, selectedCost: action.selectedCost }
        case 'RESET':
            return { ...(state = initialState) }
        default:
            return { ...state }
    }
}

export default cost
