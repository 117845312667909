// ** Initial State
const initialState = {
    allData: [],
    data: [],
    totalPages: 1,
    params: {},
    selectedProduct: null,
}

const product = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ALL_PRODUCTS_DATA':
            return { ...state, allData: action.data }

        case 'GET_PRODUCTS_DATA':
            return {
                ...state,
                data: action.data,
                totalPages: action.totalPages,
                params: action.params,
            }
        case 'GET_PRODUCT':
            return { ...state, selectedProduct: action.selectedProduct }
        case 'ADD_PRODUCT':
            return { ...state }
        case 'DELETE_PRODUCT':
            return { ...state }
        case 'UPDATE_PRODUCT':
            return { ...state }
        case 'CLEAR_PRODUCT':
            return { ...state, selectedProduct: action.selectedProduct }
        default:
            return { ...state }
    }
}

export default product
