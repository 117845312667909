// ** Initial State
const initialState = {
    allData: [],
    data: [],
    cities: [],
    sectors: [],
    totalPages: 1,
    params: {},
    selectedCompany: null,
    selectedSchedulesCompany: [],
    companyId: null,
    cepValue: null,
}

const company = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ALL_COMPANIES_DATA':
            return { ...state, allData: action.data }
        case 'GET_COMPANIES_DATA':
            return {
                ...state,
                data: action.data,
                totalPages: action.totalPages,
                params: action.params,
            }
        case 'GET_COMPANY':
            return { ...state, selectedCompany: action.selectedCompany }
        case 'CLEAR_COMPANY':
            return { ...state, selectedCompany: action.selectedCompany }
        case 'GET_SCHEDULES_COMPANY':
            return {
                ...state,
                selectedSchedulesCompany: action.selectedSchedulesCompany,
            }
        case 'ADD_COMPANY_NOTE':
            return { ...state }
        case 'ADD_COMPANY':
            return { ...state, companyId: action.companyId }
        case 'DELETE_COMPANY':
            return { ...state }
        case 'UPDATE_COMPANY':
            return { ...state }
        case 'GET_CITIES':
            return { ...state, cities: action.data }
        case 'GET_SECTORS':
            return { ...state, sectors: action.data }
        case 'DELETE_COMPANY_LOCATION':
            return { ...state }
        case 'UPDATE_COMPANY_LOCATION':
            return { ...state }
        case 'ADD_COMPANY_LOCATION':
            return { ...state }
        case 'DELETE_COMPANY_CONTACT':
            return { ...state }
        case 'UPDATE_COMPANY_CONTACT':
            return { ...state }
        case 'ADD_COMPANY_CONTACT':
            return { ...state }
        case 'UPDATE_COMPANY_BANK':
            return { ...state }
        case 'ADD_COMPANY_BANK':
            return { ...state }
        case 'UPDATE_COMPANY_LOGO':
            return { ...state, selectedCompany: action.selectedCompany }
        case 'DELETE_COMPANY_LOGO':
            return { ...state }
        case 'GET_CEP':
            return {
                ...state,
                cepValue: action.cepValue,
            }
        default:
            return { ...state }
    }
}

export default company
